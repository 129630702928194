@import-normalize;

html, body, #root {
  margin: 0;
  min-height: 100vh;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ol {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.google-map-marker {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  cursor: pointer;
}

.google-map-marker > .google-map-marker-rating {
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 6px;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  z-index: 110;
}

.google-map-marker > .google-map-marker-caret-inner {
  position: relative;
  top: -1px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  z-index: 112;
}

.google-map-marker > .google-map-marker-caret-outer {
  position: relative;
  top: -7px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #e0e0e0;
  z-index: 111;
}

.google-map-marker-details {
  display: none;
  position: absolute;
  bottom: 64px;
  background-color: white;
  padding: 16px;
  width: 264px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  z-index: 9999999;
}

.google-map-marker:hover > .google-map-marker-details {
  display: block;
}

.google-map-marker-details > img {
  width: 100%;
  margin-bottom: 8px;
  border-radius: 6px;
}

.google-map-maker-details-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 6px;
}
